// @ts-check
const env = process.env.NIARA_DEPLOY_ENV || 'dev'

export const getHotelAvailResultsAPI = {
  endpoint: 'https://7gnm6iewjff2pfxnpfzncew64e.appsync-api.us-east-1.amazonaws.com/graphql',
  region: 'us-east-1',
}

const endpoints = {
  'Spear-GetHotelAvail-API': {
    endpoint: process.env.NIARA_APP_SELF_BOOKING_API_ENDPOINT,
    service: 'execute-api',
    region: 'sa-east-1',
    authenticationType: 'NIARA_AUTH',
  },
  'niara-spear-auth': {
    endpoint: `https://o5lnfrpoa0.execute-api.sa-east-1.amazonaws.com/${env}`,
    service: 'execute-api',
    region: 'sa-east-1',
  },
  'Spear-DestinationSearch-API': {
    endpoint: `https://search-niara-es-p1-xvzgh5kttdiuj6rxgsxtjgtwwm.sa-east-1.es.amazonaws.com/${env}`,
    service: 'es',
    region: 'sa-east-1',
  },
  'Spear-FlightDestinationSearch-API': {
    endpoint: `https://search-niara-es-p1-xvzgh5kttdiuj6rxgsxtjgtwwm.sa-east-1.es.amazonaws.com/flightdest_v1_${env}`,
    service: 'es',
    region: 'sa-east-1',
  },
  'niara-spear-resource': {
    endpoint: env === 'dev' ? `https://domd4rxpsl8d2.cloudfront.net` : 'https://dvzf45pftescl.cloudfront.net',
    // service: "execute-api",
    region: 'sa-east-1',
  },
  'niara-spear-booking': {
    endpoint: process.env.NIARA_APP_SELF_BOOKING_API_ENDPOINT,
    service: 'execute-api',
    region: 'sa-east-1',
  },
  'niara-spear-tenant-admin': {
    endpoint: `https://7la3ioxksk.execute-api.sa-east-1.amazonaws.com/${env}`,
    service: 'execute-api',
    region: 'sa-east-1',
  },
  'niara-spear-flights': {
    endpoint: `https://opj9qcbrkf.execute-api.sa-east-1.amazonaws.com/${env}`,
    service: 'execute-api',
    region: 'sa-east-1',
  },
  'niara-spear-landing-page': {
    endpoint: process.env.NIARA_APP_LANDING_PAGE_API_ENDPOINT,
    service: 'execute-api',
    region: 'sa-east-1',
  },
  'niara-spear-obt-orders': {
    endpoint: process.env.NIARA_APP_OBT_ORDERS_API_ENDPOINT,
    service: 'execute-api',
    region: 'sa-east-1',
  },
  'niara-spear-obt-orders-self': {
    endpoint: process.env.NIARA_APP_OBT_ORDERS_API_ENDPOINT + '/self',
    service: 'execute-api',
    region: 'sa-east-1',
    authenticationType: 'TOKEN',
  },
  'niara-spear-obt-self-booking': {
    endpoint: process.env.NIARA_APP_SELF_BOOKING_API_ENDPOINT + '/self',
    service: 'execute-api',
    region: 'sa-east-1',
    authenticationType: 'TOKEN',
  },
  'niara-spear-obt-booking': {
    endpoint: process.env.NIARA_APP_SELF_BOOKING_API_ENDPOINT,
    service: 'execute-api',
    region: 'sa-east-1',
  },
  'niara-spear-commons': {
    endpoint: process.env.NIARA_APP_COMMONS_API_ENDPOINT,
    service: 'execute-api',
    region: 'sa-east-1',
    authenticationType: 'NIARA_AUTH',
  },
  'niara-api': {
    endpoint: `https://api.niara.tech/${env}`,
    service: 'execute-api',
    region: 'sa-east-1',
    authenticationType: 'AWS_IAM',
  },
  'niara-spear-payments': {
    endpoint: process.env.NIARA_APP_PAYMENTS_API_ENDPOINT,
    service: 'execute-api',
    region: 'sa-east-1',
    authenticationType: 'NIARA_AUTH',
  },
  'niara-spear-payments-self': {
    endpoint: process.env.NIARA_APP_PAYMENTS_API_ENDPOINT + '/self',
    service: 'execute-api',
    region: 'sa-east-1',
    authenticationType: 'TOKEN',
  },
  'niara-spear-finances': {
    endpoint: process.env.NIARA_APP_FINANCES_API_ENDPOINT,
    service: 'execute-api',
    region: 'sa-east-1',
    authenticationType: 'NIARA_AUTH',
  },
  'obauth-userpool': {
    endpoint: process.env.NIARA_APP_OBAUTH_USERPOOL_API_ENDPOINT,
    service: 'execute-api',
    region: 'us-east-1',
  },
  'create-tenant': {
    endpoint: process.env.NIARA_APP_CREATE_TENANT_API_ENDPOINT,
    service: 'execute-api',
    region: 'us-east-1',
  },
  'create-tenant@homolog': {
    /** homolog.niara.tech precisa chamar este endpoint, e não de produção */
    endpoint: 'https://o8w3uquxh2.execute-api.us-east-1.amazonaws.com/homolog',
    service: 'execute-api',
    region: 'us-east-1',
  },
  orders: {
    endpoint: process.env.NIARA_APP_ORDERS_ENDPOINT,
    service: 'execute-api',
    region: 'sa-east-1',
  },
  ledgers: {
    endpoint: process.env.NIARA_APP_LEDGERS_ENDPOINT,
    service: 'execute-api',
    region: 'sa-east-1',
  },
  'lost-reservations': {
    endpoint: process.env.NIARA_APP_LOST_RESERVATIONS_ENDPOINT,
    service: 'execute-api',
    region: 'sa-east-1',
    authenticationType: 'NIARA_AUTH',
  },
  'loyalty-programs': {
    endpoint: process.env.NIARA_APP_LOYALTY_PROGRAMS_ENDPOINT,
    service: 'execute-api',
    region: 'sa-east-1',
    authenticationType: 'NIARA_AUTH',
  },
  reports: {
    endpoint: process.env.NIARA_APP_REPORTS_ENDPOINT,
    service: 'execute-api',
    region: 'sa-east-1',
  },
  executeScript: {
    endpoint: ' https://7ymp7phfs8.execute-api.sa-east-1.amazonaws.com/dev',
    service: 'execute-api',
    region: 'sa-east-1',
  },
  'commons-integrations': {
    endpoint: process.env.NIARA_APP_COMMONS_INTEGRATION_ENDPOINT,
    authenticationType: 'NONE',
  },
  'content-integrations': {
    endpoint: process.env.NIARA_APP_CONTENT_INTEGRATION_ENDPOINT,
    service: 'execute-api',
    region: 'sa-east-1',
  },
  gds: {
    endpoint: process.env.NIARA_APP_GDS_ENDPOINT,
    service: 'execute-api',
    region: 'sa-east-1',
  },
  'niara-auth': {
    endpoint:
      process.env.DEV_OR_HOMOLOG_OR_PROD === 'prod'
        ? 'https://niara-auth.niara.tech/prod'
        : process.env.DEV_OR_HOMOLOG_OR_PROD === 'homolog'
        ? 'https://niara-auth.homolog.niara.tech/homolog'
        : 'https://niara-auth.dev.niara.tech/dev',
    service: 'execute-api',
    region: 'sa-east-1',
  },
  crudApi2: {
    endpoint: process.env.NIARA_APP_CRUD_API_2_ENDPOINT,
    authenticationType: 'NIARA_AUTH',
  },
  'niara-spear-single-sign-on': {
    endpoint: process.env.NIARA_APP_SINGLE_SIGN_ON_ENDPOINT,
    authenticationType: 'NIARA_AUTH',
  },
  'niara-spear-niara-account-backend': {
    endpoint: process.env.NIARA_APP_NIARA_ACCOUNT_BACKEND_ENDPOINT,
    authenticationType: 'NIARA_AUTH',
  },
  'safe-api': {
    endpoint:
      process.env.DEV_OR_HOMOLOG_OR_PROD === 'dev'
        ? 'https://f8mld0mo41.execute-api.sa-east-1.amazonaws.com'
        : 'https://7rd1tvizo6.execute-api.sa-east-1.amazonaws.com',
  },
  'niara-spear-credit-control': {
    endpoint: process.env.NIARA_APP_CREDIT_CONTROL_ENDPOINT,
    authenticationType: 'NIARA_AUTH',
  },
  'niara-spear-clients': {
    endpoint: process.env.NIARA_APP_CLIENTS_API_ENDPOINT,
    authenticationType: 'NIARA_AUTH',
  },
}

export type ApiName = keyof typeof endpoints

export default endpoints
